<template>
  <login-form @clickLogin="clickLogin($event)" @signup="signup($event)"></login-form>
</template>
<script>
import AppLogin from "../../library/auth/login";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
//import { nullOrUndefined } from "../../../../plugins/libs.js";

export default {
  components: {
    "login-form": AppLogin,
  },
  data() {
    return {
      valid: false,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      user: {},
    };
  },
  mounted() {
    if (this.$cookies.get("Auth").authType != "LAB") {
      //this.logout();
      this.$cookies.set("Auth", {});
      this.$cookies.set("Profile", {});
    } else {
      if (
        this.$cookies.get("Profile")._id &&
        this.cookies.get("Auth").authType == "LAB"
      ) {
        this.$route.push("/dashboard");
      }
    }
    console.log(this.$route);
  },
  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      // getInfo: "BFC/getDoctorInfo",
    }),
  },
  methods: {
    ...mapActions({
      //auth: "BFC/adminAuth",
      //setInfo: "BFC/setDoctorInfo",
      setAllCases: "BFC/setAllCases",
      setAuth: "BFC/setAuth",
      setLabProfile: "BFC/setLabProfile",
    }),
    async clickLogin(payload) {
      console.log("login");
      //console.log(payload);
      try {
        //let payload
        let payloadAuth = {
          _id: "",
          authType: "LAB",
          token: "",
        };
        await this.setAuth(payloadAuth);
        //await this.listCases();
        //this.$router.push("/lab/dashboard");
        await axios
          .post(
            this.url + "/api/bfc/v1/lab/signin",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              let payloadAuth = {
                _id: response.data.member._id,
                authType: "LAB",
                lastLogin: new Date(),
                token: "",
                custId: response.data.lab[0].custId,
              };

              this.$cookies.set("Auth", payloadAuth);
              response.data.member[0].password = "";
              //await this.auth(payload);
              //console.log(this.$cookies.get("Auth").authType);
              //await this.setInfo(response.data);
              await this.setAuth(payloadAuth);
              await this.setLabProfile(response.data.member[0]);
              this.$cookies.set("Profile", response.data);

              //await this.listCases();
              // doctor info

              //console.log(this.getInfo);
              this.$router.push("/lab/dashboard");
            } else {
              // show error
              console.log("error response");
              alert("Login failed");
            }
          });
      } catch (e) {
        //
        console.log(e);
        alert("Login failed");
      }
    },

    signup() {
      //console.log(e);
      this.$router.push("/lab/sign-up");
    },
  },
};
</script>
